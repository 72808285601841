import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import config from 'src/app/configs';
import {ConstructionSiteService} from 'src/app/services/construction-site/construction-site.service';
import {HttpService} from 'src/app/services/http/http.service';
import {UtilityService} from 'src/app/services/utility/utility.service';
import Cookies from 'js-cookie';


interface WorkEntryRaw {
    user: number;
    id?: number;
    work_start?: string;
    work_end?: string;
    lunch_and_breaks?: number;
    driving_time?: number;
    date: string;
    location?: string
    work_type: string
}


export interface WorkEntryModel {
    user: number;
    id: number;
    work_start?: string;
    work_end?: string;
    lunch_and_breaks?: string;
    driving_time?: string;
    date: Date;
    location_id: number | null;
    location?: string
    work_type: string;
}


@Injectable({
    providedIn: 'root'
})
export class TimeTrackingService {
    static BASE_URL = `${config.apiHost}/work-entries/`;
    static BASE_URL_USERS = `${config.apiHost}/users/`;

    constructor(
        private http: HttpService,
        private constructionSiteService: ConstructionSiteService,
        private utilityService: UtilityService,
    ) {
    }

    async getEntryByDate(userId: number, date: Date = new Date()): Promise<WorkEntryModel | null> {
        const dateFormatted = this.constructionSiteService.formatDate(date);
        const workEntries: any = await this.http
            .get(`${TimeTrackingService.BASE_URL_USERS}${userId}/work-entries/?date=${dateFormatted}`)
            .toPromise();
        const entry = workEntries[0] as any;
        if (entry) {
            entry.lunch_and_breaks = this.utilityService.convertFloatToTime(entry.lunch_and_breaks);
            entry.driving_time = this.utilityService.convertFloatToTime(entry.driving_time);
            entry.date = new Date(entry.date);            
            return entry;
        } else {
            return null;
        }
    }

    async getFirstAvailableDate(userId: number): Promise<Date> {
        const userData: any = await this.http
            .get(`${TimeTrackingService.BASE_URL_USERS}${userId}/`)
            .toPromise();
        const userEntries: WorkEntryRaw[] = userData.work_entries;
        const workHistoryDates = userEntries.map((dateElement: WorkEntryRaw) => {
            return dateElement.date;
        });
        const dateFirst = new Date(workHistoryDates.sort()[0]);
        dateFirst.setHours(0);
        dateFirst.setMinutes(0);
        dateFirst.setSeconds(0);
        dateFirst.setMilliseconds(0);
        return dateFirst;
    }

    async updateEntry(userId: number, form: FormGroup): Promise<any> {

        const isWorkday = form.controls.workType.value === 'w'

        const workEntry: WorkEntryRaw = {
            user: userId,
            id: form.controls.id.value,
            work_start: isWorkday ? form.controls.workStart.value : null,
            work_end: isWorkday ? form.controls.workEnd.value: null,
            lunch_and_breaks: isWorkday ? this.utilityService.convertTimeToFloat(form.controls.lunchAndBreaks.value) : null,
            driving_time: isWorkday ? this.utilityService.convertTimeToFloat(form.controls.drivingTime.value) : null,
            date: this.constructionSiteService.formatDate(
                form.controls.date.value.setHours(0, 0, 0, 0)
            ),
            work_type: form.controls.workType.value,
            location: form.controls.location.value,
        };

        return this.http
            .patch(`${TimeTrackingService.BASE_URL}${form.controls.id.value}/`, workEntry)
            .toPromise();
    }

    async addEntry(userId: number, form: FormGroup): Promise<any> {

        const isWorkday = form.controls.workType.value === 'w'

        const workEntry: WorkEntryRaw = {
            user: userId,
            work_start: isWorkday ? form.controls.workStart.value : null,
            work_end: isWorkday ? form.controls.workEnd.value : null,
            lunch_and_breaks: isWorkday ? this.utilityService.convertTimeToFloat(form.controls.lunchAndBreaks.value) : null,
            driving_time: isWorkday ? this.utilityService.convertTimeToFloat(form.controls.drivingTime.value) : null,
            date: this.constructionSiteService.formatDate(form.controls.date.value),
            work_type: form.controls.workType.value,
            location: form.controls.location.value,
        };

        return this.http
            .post(TimeTrackingService.BASE_URL, workEntry)
            .toPromise();
    }
}
