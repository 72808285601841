import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable, throwError, forkJoin, from } from 'rxjs';
import { first, catchError } from 'rxjs/operators';
import {UserService} from '../user/user.service';
import {Router} from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';


interface HttpOptions {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(
        private http: HttpClient,
        private userService: UserService,
        private router: Router,
        private modalController: ModalController,
        private toastController: ToastController,
    ) {}

    private getAuthHeaders(): HttpHeaders {
        // Get the token from localStorage
        const token = localStorage.getItem('AUTH_TOKEN');
        let headers = new HttpHeaders();

        // If a token exists, add it to the headers
        if (token) {
            headers = headers.set('Authorization', `Token ${token}`);
        }

        return headers;
    }

    private handleError(error: any) {
        if (error.status === 401) {
            localStorage.clear();
            this.userService.user = null;
            forkJoin([
                from(this.modalController.dismiss()).pipe(catchError(() => from(this.router.navigate(['/login'])))),
                from(this.toastController.dismiss()).pipe(catchError(() => from(this.router.navigate(['/login']))))
              ]).subscribe(() => {
                // If everything completes successfully or even if there's an error, navigate to login
                this.router.navigate(['/login']);
              });
        }
        return throwError(error);
    }

    get<T>(url: string, options?: HttpOptions) {
        const headers = this.getAuthHeaders();
        const updatedOptions = { ...options, headers };
        return this.http.get<T>(url, updatedOptions).pipe(
            first(),
            catchError((error) => this.handleError(error)) // Catch 401 errors
        );
    }

    post<T>(url: string, body: any | null, options?: HttpOptions): Observable<T> {
        const headers = this.getAuthHeaders();
        const updatedOptions = { ...options, headers };
        return this.http.post<T>(url, body, updatedOptions).pipe(
            first(),
            catchError((error) => this.handleError(error)) // Catch 401 errors
        );
    }

    put<T>(url: string, body: any | null, options?: HttpOptions) {
        const headers = this.getAuthHeaders();
        const updatedOptions = { ...options, headers };
        return this.http.put<T>(url, body, updatedOptions).pipe(
            first(),
            catchError((error) => this.handleError(error)) // Catch 401 errors
        );
    }

    patch<T>(url: string, body: any | null, options?: HttpOptions) {
        const headers = this.getAuthHeaders();
        const updatedOptions = { ...options, headers };
        return this.http.patch<T>(url, body, updatedOptions).pipe(
            first(),
            catchError((error) => this.handleError(error)) // Catch 401 errors
        );
    }

    delete<T>(url: string, options?: HttpOptions): Observable<T | object> {
        const headers = this.getAuthHeaders();
        const updatedOptions = { ...options, headers };
        return this.http.delete<T>(url, updatedOptions).pipe(
            first(),
            catchError((error) => this.handleError(error)) // Catch 401 errors
        );
    }
}
