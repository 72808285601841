import {Injectable} from '@angular/core';
import {tap, switchMap} from 'rxjs/operators';
import config from '../../configs';
import {HttpService} from '../http/http.service';
import UserModel from '../user/user.model';
import {UserService} from '../user/user.service';
import {Router} from '@angular/router';

export interface ILoginResponse {
    token: string;
    user: UserModel;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    static AUTH_TOKEN = 'AUTH_TOKEN';
    static USER = 'user';

    constructor(
        private httpService: HttpService,
        private userService: UserService,
        private router: Router
    ) {
    }

    login(username: string, password: string) {
        return this.httpService.post<any>(`${config.accountHost}/api-token-auth/`, {
            username,
            password
        }).pipe(
            tap((data: any) => {
                localStorage.setItem(AuthService.AUTH_TOKEN, data.token);
            }),
            switchMap((data: any) => this.getCurrentUser(data.token)) // This will return an observable
        );
    }
    
    private getCurrentUser(token: string) {
        return this.httpService.get<any>(`${config.accountHost}/api/me/`, {
            headers: {
                Authorization: `Token ${token}`
            }
        }).pipe(
            tap((user: any) => {
                this.userService.user = user;
                localStorage.setItem(AuthService.USER, JSON.stringify(user));
            })
        );
    }

    logout() {
        localStorage.clear();
        this.userService.user = null;
        this.router.navigate(['/login']);
    }

}
